import React, { useState } from "react";
import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import "../style/forms.css";
import "../style/base.css";
import { app } from "../functions/firebase";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { createCateringSendDetail } from "../functions/createOrderString";

const H2 = (props) => {
  return (
    <BoxTypo
      m="56px auto"
      fs={["24px", "26px"]}
      ls={["2px"]}
      lh={["200%"]}
      fw={["500"]}
      color={"#515D59"}
      className="tac"
    >
      {props.children}
    </BoxTypo>
  );
};
const H3 = (props) => {
  return (
    <BoxTypo
      {...props}
      mt={6}
      fs={["18px", "20px"]}
      ls={["1px"]}
      lh={["200%"]}
      fw="500"
      color={"#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};
const P = (props) => {
  return (
    <BoxTypo
      {...props}
      fs={["14px", "16px"]}
      ls={["2px"]}
      lh={["200%"]}
      color={props.color ? props.color : "#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};

const ValidationView = (props) => {
  const { inputData, setOpenModal } = props;
  const db = getFirestore(app);
  const onSubmit = async () => {
    try {
      const docRef = await addDoc(collection(db, "contact"), {
        detail: createCateringSendDetail(inputData),
        created_at: Date.now(),
      })
        .then((_) => {
          window.location.href = window.location.origin + "/thanks";
        })
        .catch((e) => {
          console.log("Error が発生しました");
          // console.error("Error adding document: ", e);
          alert(
            "データ送信の過程で、エラーが発生致しました。お手数をおかけしますが、forucafe本店(03-6233-8739)までお電話ください。"
          );
        });
    } catch (e) {
      alert(
        "データ送信の過程で、エラーが発生致しました。お手数をおかけしますが、forucafe本店(03-6233-8739)までお電話ください。"
      );
      console.error("Error adding document: ", e);
      console.log("Error が発生しました", { e });
    }
  };
  return (
    <>
      <Box maxWidth={"600px"} width="90%" m={"auto"}>
        <H2>ご依頼の確認</H2>
        <P style={{ textAlign: "left" }}>
          ご注文内容にお間違いがないかご確認いただき、「注文を確定する」ボタンを押してください。
        </P>
        <P mt={4} mb={1}>
          会社名/団体名
        </P>
        <P className="dataCheck">{inputData?.data?.groupName}</P>
        <P mt={4} mb={1}>
          ご担当者名(漢字)
        </P>
        <P className="dataCheck">{inputData?.data?.name}</P>
        <P mt={4} mb={1}>
          ご担当者名(カナ)
        </P>
        <P className="dataCheck">{inputData?.data?.nameKana}</P>
        <P mt={4} mb={1}>
          メールアドレス
        </P>
        <P className="dataCheck">{inputData?.data?.email}</P>
        <P type={"text"} mt={4} mb={1}>
          電話番号
        </P>
        <P className="dataCheck">{inputData?.data?.tell}</P>
        <P mt={4} mb={1}>
          当日連絡先
        </P>
        <P className="dataCheck">{inputData?.data?.onTheDayContact}</P>
        <P mt={4} mb={1}>
          お食事プラン
        </P>
        <P className="dataCheck">{inputData?.data?.plane}</P>
        <P mt={4} mb={1}>
          ドリンク
        </P>
        <P className="dataCheck">{inputData?.data?.drink}</P>
        <P mt={4} mb={1}>
          お支払い方法
        </P>
        <P className="dataCheck">{inputData?.data?.paymentMethod}</P>
        <P mt={4} mb={1}>
          領収書
        </P>
        <P className="dataCheck">{inputData?.data?.invoice}</P>
        <P mt={4} mb={1}>
          ご希望人数
        </P>
        <P className="dataCheck">{inputData?.data?.peopleNum}</P>
        <P mt={4} mb={1}>
          配送先先ご住所
        </P>
        <P className="dataCheck">{inputData?.data?.address}</P>
        <P mt={4} mb={1}>
          ご利用日・セッティング完了時刻
        </P>
        <P className="dataCheck">
          {inputData?.data?.receiveDate}　{inputData?.data?.receiveTimeHour}時
          {inputData?.data?.receiveTimeMin}分　
        </P>
        <P mt={4} mb={1}>
          撤収希望日時(任意)
        </P>
        <P className="dataCheck">
          {inputData?.data?.RemovalTime ? inputData?.data?.RemovalTime : "なし"}
        </P>
        <P mt={4} mb={1}>
          オリジナルフラッグ
        </P>
        <P className="dataCheck">{inputData?.data?.flag}</P>
        <P mt={4} mb={1}>
          ご利用シーン
        </P>
        <P className="dataCheck">{inputData?.data?.sean}</P>
        <P mt={4} mb={1}>
          ご要望やご質問
        </P>
        <P className="dataCheck">{inputData?.data?.something}</P>
        <button className="btn back" onClick={() => setOpenModal((_) => false)}>
          <Grid container>
            <Grid item xs={3}>
              <P>{"＜"}</P>
            </Grid>
            <Grid item xs={6}>
              <P>修正する</P>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </button>
        <button
          className="btn next"
          onClick={() => {
            onSubmit();
          }}
        >
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <P color="#fff">注文を確定する</P>
            </Grid>
            <Grid item xs={3}>
              <P color="#fff">{"＞"}</P>
            </Grid>
          </Grid>
        </button>
      </Box>
    </>
  );
};

const FoodConform = (props) => {
  const { orderNum, id, price, name, sumPrice } = props;
  return (
    <>
      {orderNum[id] === 0 ? (
        <></>
      ) : (
        <Box width={"100%"} mt={3}>
          <img src={props.img} style={{ width: "100%", objectFit: "cover" }} />
          <Box className="df jcsb" width={"100%"} mt={1}>
            <Box width={"40%"}>
              <BoxTypo
                color={"#515D59"}
                fs={["12px"]}
                ls={["2px"]}
                lh={["200%"]}
                fw="300"
              >
                ¥{price}
              </BoxTypo>
              <BoxTypo
                color={"#515D59"}
                fs={["16px"]}
                ls={["2px"]}
                lh={["200%"]}
              >
                {name}
              </BoxTypo>
            </Box>
            <Box width={"60%"} className="df jcsb alis tar mr">
              <Box
                width={"40%"}
                className="mr"
                style={{
                  height: "100%",
                  color: "#515D59",
                  border: "none",
                }}
              >
                <BoxTypo className="tac" fs={["14px"]} ls={["2px"]} mt={"14px"}>
                  {orderNum[id]}個
                </BoxTypo>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ValidationView;
