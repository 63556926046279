import React, { useState } from "react";
import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import "../style/forms.css";
import "../style/base.css";

const H2 = (props) => {
  return (
    <BoxTypo
      m="56px auto"
      fs={["24px", "26px"]}
      ls={["2px"]}
      lh={["200%"]}
      fw={["500"]}
      color={"#515D59"}
      className="tac"
    >
      {props.children}
    </BoxTypo>
  );
};
const H3 = (props) => {
  return (
    <BoxTypo
      {...props}
      mt={6}
      fs={["18px", "20px"]}
      ls={["1px"]}
      lh={["200%"]}
      fw="500"
      color={"#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};
const P = (props) => {
  return (
    <BoxTypo
      {...props}
      fs={["14px", "16px"]}
      ls={["2px"]}
      lh={["200%"]}
      color={props.color ? props.color : "#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};

const Forms = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { setData, setOpenModal } = props;
  const onSubmit = (data) => {
    setData({ data: data });
    setOpenModal((_) => true);
  };
  const validateNumbers = (value) => {
    return /^\d+$/.test(value) || "電話番号の入力は数字のみにしてください";
  };
  return (
    <Box maxWidth={"1800px"} width="90%" m={"auto"}>
      <Box
        width="100%"
        maxWidth={"600px"}
        bgcolor={Object.keys(errors).length ? "#ffdddd" : "#fff"}
        textAlign="left"
        pl="32px"
        py={Object.keys(errors).length ? 5 : 0}
      >
        <P color="red" mb={2}>
          {Object.keys(errors).length ? "下記の内容を修正してください" : ""}
        </P>
        <P color="red">{errors.groupName && "・" + errors.groupName.message}</P>
        <P color="red">{errors.name && "・" + errors.name.message}</P>
        <P color="red">{errors.nameKana && "・" + errors.nameKana.message}</P>
        <P color="red">{errors.email && "・" + errors.email.message}</P>
        <P color="red">
          {errors.tell?.type === "required" && "・" + errors.tell.message}
        </P>
        <P color="red">
          {errors.tell?.type === "maxLength" &&
            "・" + "電話番号は12桁、または13桁でご入力ください"}
        </P>
        <P color="red">
          {errors.tell?.type === "minLength" &&
            "・" + "電話番号は12桁、または13桁でご入力ください"}
        </P>
        <P color="red">
          {errors.tell?.type === "validate" && "・" + errors.tell.message}
        </P>
        <P color="red">
          {errors.onTheDayContact && "・" + errors.onTheDayContact.message}
        </P>
        <P color="red">
          {errors.paymentMethod && "・" + errors.paymentMethod.message}
        </P>
        <P color="red">{errors.invoice && "・" + errors.invoice.message}</P>
        <P color="red">
          {errors.receiveMethod && "・" + errors.receiveMethod.message}
        </P>
        <P color="red">{errors.address && "・" + errors.address.message}</P>
        <P color="red">
          {errors.receiveDate && "・" + errors.receiveDate.message}
        </P>
        <P color="red">
          {errors.receiveTimeHour && "・" + errors.receiveTimeHour.message}
        </P>
        <P color="red">
          {errors.receiveTimeMin && "・" + errors.receiveTimeMin.message}
        </P>
        <P color="red">{errors.plane && "・" + errors.plane.message}</P>
        <P color="red">{errors.drink && "・" + errors.drink.message}</P>
        <P color="red">{errors.peopleNum && "・" + errors.peopleNum.message}</P>
        <P color="red">
          {errors.receiveTimeHour && "・" + errors.receiveTimeHour.message}
        </P>
        <P color="red">
          {errors.receiveTimeMin && "・" + errors.receiveTimeMin.message}
        </P>
        <P color="red">
          {errors.RemovalTime && "・" + errors.RemovalTime.message}
        </P>
        <P color="red">{errors.flag && "・" + errors.flag.message}</P>
        <P color="red">{errors.something && "・" + errors.something.message}</P>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className="tal">
        <Box width={"100%"} maxWidth={"600px"} m={"auto"}>
          <P mt={4} mb={1}>
            会社名/団体名
          </P>
          <input
            type={"text"}
            defaultValue=""
            {...register("groupName", {
              required: "会社名/団体名が未入力です",
            })}
          />
          <P mt={4} mb={1}>
            ご担当者名(漢字)
          </P>
          <input
            placeholder="氏名"
            type={"text"}
            {...register("name", {
              required: "ご担当者様が未入力です",
            })}
          />
          <P mt={4} mb={1}>
            ご担当者名(カナ)
          </P>
          <input
            type={"text"}
            placeholder="カナ"
            defaultValue=""
            {...register("nameKana", {
              required: "ご担当者様のカナが未入力です",
            })}
          />
          <P mt={4} mb={1}>
            メールアドレス
          </P>
          <input
            type={"email"}
            placeholder="sample@email.com"
            defaultValue=""
            {...register("email", {
              required: "メールアドレスが未入力です",
              pattern: "/[w-._]+@[w-._]+.[A-Za-z]+/')/",
            })}
          />
          <P type={"text"} mt={4} mb={1}>
            電話番号
          </P>
          <input
            type={"tel"}
            placeholder="09000000000"
            defaultValue=""
            {...register("tell", {
              required: "電話番号が未入力です",
              number: "ハイフンなしでご入力ください",
              validate: validateNumbers,
              maxLength: 12,
            })}
          />
          <P mt={4} mb={1}>
            当日連絡先
          </P>
          <input
            type={"text"}
            placeholder="09000000000"
            defaultValue=""
            {...register("onTheDayContact", {
              required: "当日連絡先が未入力です",
            })}
          />
          <P my={4}> お食事プラン</P>
          <P my={2} className="taj">
            <input
              type="radio"
              name="plane"
              value="ライトプラン /お一人様 2,000円"
              {...register("plane", { required: "お食事プランが未入力です" })}
            />
            ライトプラン /お一人様 2,000円
          </P>
          <P my={2} className="taj">
            <input
              type="radio"
              name="plane"
              value="スタンダードプラン  /お一人様 2,500円"
              {...register("plane", { required: "お食事プランが未入力です" })}
            />
            スタンダードプラン /お一人様 2,500円
          </P>
          <P my={2} className="taj">
            <input
              type="radio"
              name="plane"
              value="プレミアムプラン /お一人様 3,000円"
              {...register("plane", { required: "お食事プランが未入力です" })}
            />
            プレミアムプラン /お一人様 3,000円
          </P>
          <P sx={{ mt: { sx: 4, md: 6 } }} style={{ marginTop: "32px" }}>
            ドリンク
          </P>
          <P my={2} className="taj">
            <input
              type="radio"
              name="drink"
              value="アルコールプラン　/お一人様2~3杯　2,000円
            ビール・ワイン・緑茶・オレンジジュース・ジンジャエール"
              {...register("drink", { required: "ドリンクプランが未入力です" })}
            />
            アルコールプラン　/お一人様2~3杯　2,000円(ビール・ワイン・緑茶・オレンジジュース・ジンジャエール)
          </P>
          <P my={2} className="taj">
            <input
              type="radio"
              name="drink"
              value="ノンアルコールプラン　/お一人様2~3杯　1,200円
            緑茶・オレンジジュース・ジンジャエール"
              {...register("drink", { required: "ドリンクプランが未入力です" })}
            />
            ノンアルコールプラン　/お一人様2~3杯　1,200円(緑茶・オレンジジュース・ジンジャエール)
          </P>
          <P my={2} className="taj">
            <input
              type="radio"
              name="drink"
              value="なし"
              {...register("drink", { required: "ドリンクプランが未入力です" })}
            />
            なし
          </P>
          <P mt={4} mb={1}>
            お支払い方法
          </P>
          <P>
            <input
              type="radio"
              name="paymentMethod"
              value="請求書払い"
              {...register("paymentMethod", {
                required: "お支払い方法が未入力です",
              })}
            />
            請求書払い
          </P>
          <P>
            <input
              type="radio"
              name="paymentMethod"
              value="当日現金払い"
              {...register("paymentMethod", {
                required: "お支払い方法が未入力です",
              })}
            />
            当日現金払い
          </P>
          <P mt={4} mb={1}>
            領収書
          </P>
          <P>
            <input
              type="radio"
              name="invoice"
              value="あり"
              {...register("invoice", { required: "領収書の欄が未入力です" })}
            />
            あり
          </P>
          <P>
            <input
              type="radio"
              name="invoice"
              value="なし"
              {...register("invoice", { required: "領収書の欄が未入力です" })}
            />
            なし
          </P>
          <P mt={4} mb={1}>
            ご希望人数
          </P>
          <input
            type={"text"}
            defaultValue=""
            {...register("peopleNum", {
              required: "ご希望人数が未入力です",
            })}
          />
          <P mt={4} mb={1}>
            配送先ご住所(基本的には東京23区内のみ対応しております。住所は建物名までお書きください)
          </P>
          <input
            type={"text"}
            defaultValue=""
            {...register("address", {
              required: "住所が未入力です",
            })}
          />
          <P mt={4} mb={1}>
            ご利用日（ご予約は基本的に2週間前まで承ります。）
          </P>
          <input
            type="date"
            {...register("receiveDate", { required: "ご利用日が未入力です" })}
          />
          <P mt={4} mb={1}>
            セッティング完了時間
            <br />
            (セッティングに30分~1時間程度お時間をいただきます)
          </P>

          <Box>
            <P>
              <select
                name="example"
                {...register("receiveTimeHour", {
                  required: "受け取り時間「時」が未入力です",
                })}
                className="date"
              >
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
              </select>
              　時　　
              <select
                name="example"
                className="date"
                {...register("receiveTimeMin", {
                  required: "受け取り時刻「分」が未入力です",
                })}
              >
                <option value="00">00</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
              　分　　
            </P>
          </Box>
          <P mt={4} mb={1}>
            撤収希望日時があればご回答ください
            <br />
            (当日または翌日以降に委託業者が撤収に伺います)
          </P>
          <input
            type={"text"}
            defaultValue=""
            {...register("RemovalTime", {
              required: "撤収時刻が未入力です",
            })}
          />
          <P mt={4} mb={1}>
            バーガーのオリジナルフラッグを希望されますか。
            <br />
            (ご希望される場合、お名前とフラッグにするjpgまたはpngデータを
            info@forustyle.com までお送りください。)
          </P>
          <input
            type={"text"}
            defaultValue=""
            placeholder="例：はい。データをメールで送ります。
            いいえ。forucafeのフラッグで結構です。"
            {...register("flag", {
              required: "オリジナルフラグに関する記述が未入力です",
            })}
          />
          <P mt={4} mb={1}>
            ご利用シーン
          </P>
          <input
            type={"text"}
            placeholder="例：会社の懇談会"
            {...register("sean", {
              required: "ご利用シーンが未入力です",
            })}
          />
          <P mt={4} mb={1}>
            ご要望やご質問がありましたらご記入ください。個数や日程が確定されていない場合は、こちらに相談事項をお書きください。
          </P>
          <textarea type={"text"} defaultValue="" {...register("something")} />
        </Box>
        <Box
          id="cancel-policy"
          m="56px auto"
          width={"100%"}
          className="tac"
          px={["10px"]}
          border={"2px solid #515D59"}
          pb={7}
        >
          <H3>
            配送・セッティング・撤収手数料
            <br className="sp" />
            について
          </H3>
          <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} mt={4}>
            お食事の料金とは別に、配送・セッティング/撤収手数料を一律1万円(税込)をいただいております。ご了承くださいませ。
          </BoxTypo>
        </Box>
        <Box
          id="cancel-policy"
          m="56px auto"
          width={"100%"}
          className="tac"
          px={["10px"]}
          border={"2px solid #515D59"}
          pb={7}
        >
          <H3>キャンセルについて</H3>
          <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} mt={4}>
            お申込み後、手配を開始してから何らかの理由でお客様が予約をキャンセルされた場合に生じる
            <br className="pc" />
            キャンセル料は、下記の規定に従いお客様にご負担いただきます。
          </BoxTypo>
          <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
            <ul>
              <P>14日前~8日前　ご注文金額の50%</P>
              <P> 7日前~2日前 ご注文金額の80%</P>
              <P>前日〜当日　ご注文金額の100%</P>
            </ul>
          </BoxTypo>
        </Box>
        <Box class="mw1100 w100 ma" style={{ marginBottom: "32px" }}>
          <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
            ※ご依頼は2週間前まで承ります。
            <br />
            ※基本的に、配送は東京23区のみ対応しております。
            <br />
            ※会場がWeWork以外のケータリングプランは3万円(税込)以上のオーダーから承ります。
            <br />
            ※お食事の料金とは別に、配送・セッティング/撤収手数料を一律1万円(税込)をいただいております。ご了承くださいませ。
            <br />
            ※日程や人数が確定していない場合も、まずは暫定の情報を以下フォームにご登録ください。
            <br />
          </BoxTypo>
        </Box>
        {/* <input type="submit" value={"注文内容の確認へ"} /> */}
        <input
          style={{
            boxShadow: "none",
            border: "solid 1px #515D59",
            outline: "none",
            marginTop: "128px",
            marginBottom: "128px",
            backgroundColor: "#fff",
            color: "#515D59",
          }}
          type="submit"
          className="btn next"
          value="注文内容の確認へ"
        />
        {/* <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <P color="#fff">注文を確認する</P>
            </Grid>
            <Grid item xs={3}>
              <P color="#fff">{"＞"}</P>
            </Grid>
          </Grid>
        </button> */}
      </form>
    </Box>
  );
};

export default Forms;
