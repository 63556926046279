import React, { useState } from "react";
import BoxTypo from "../components/BoxTypo";
import { Box, Button } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const nlRegex = /(\r\n|\r|\n)/g;
const nl2br = (str) =>
  str.split(nlRegex).map((line, i) =>
    line.match(nlRegex) ? (
      <>
        <br key={i} />
      </>
    ) : (
      line
    )
  );

const FAQ = () => {
  const tempNewsArray = [];
  const tempNoteArray = [];
  const [dlNews, setNews] = useState();
  const popularQuestion = [
    {
      question: "配送料金はかかりかますか？",
      answer:
        "お食事の料金とは別に、配送・セッティング/撤収手数料を一律1万円(税込)をいただいております。ご了承くださいませ。",
    },
    {
      question: "紙皿、お箸、お手拭きはついていますか？",
      answer: "紙皿、お箸、お手拭きを人数分おつけしています。",
    },
    {
      question: "配達可能範囲はどこまでですか？",
      answer:
        "基本は東京23区内で対応しております。その他の地域はご相談ください。",
    },
    {
      question: "最低受付金額はいくらですか？",
      answer: "最低保証料金3万円以上(配送料は除く)での注文を承っております。",
    },
    {
      question: "注文の期限はいつまでですか？",
      answer:
        "ご注文は基本的に2週間前まで承ります。時期・ご注文状況によってはご対応が難しい場合もございますのでご了承ください。",
    },
    {
      question: "どのように撤収しますか？",
      answer:
        "撤収業者を手配させていただきます。撤収方法を当日ご説明させていただきますが、備品等はコンテナにまとめていただけますと幸いです。",
    },
    {
      question: "提供までの時間はどのくらいかかりますか？",
      answer: "ご提供開始時間の30分~1時間前からセッティングを行います。",
    },
    {
      question: "キャンセル料はかかりますか？",
      answer:
        "ご予約日の14日前~8日前はご注文金額の50%、7日前~2日前はご注文金額の80%、前日~当日はご注文金額の100%です。",
    },
    {
      question: "どんなお支払い方法がありますか？",
      answer:
        "請求書支払いまたは現金支払いで承っております。現金支払いの場合、お釣りが無いようにご用意いただけますと幸いです。領収書が必要な場合は、事前にお申し付けくださいませ。",
    },
    {
      question: "賞味期限はどのくらいですか？",
      answer:
        "衛生上、セッティングから3時間程度を目処に召し上がっていただくようお願いいたします。",
    },
    {
      question: "スタッフによるサービスはありますか？",
      answer:
        "搬入、セッティング、搬出を行います。配膳サービスは基本的に行っておりません。",
    },
    {
      question: "人数の変更は何日前まで可能ですか？",
      answer:
        "ご予約日の1週間前まで変更可能です。それ以降の変更は状況によりキャンセル料が発生する場合もございますのでご相談ください。",
    },
  ];

  return (
    <>
      <Box textAlign="center" width="100%" my={"128px"}>
        <BoxTypo
          fs={["23px", "30px"]}
          ls={["2px"]}
          lh={["200%"]}
          color={"#515D59"}
          my={["32px"]}
        >
          Q&A
        </BoxTypo>
        {popularQuestion?.map((c, i) => {
          return (
            <Section
              question={c.question}
              answer={c.answer}
              index={i}
              addLink={c.option && c.option}
            />
          );
        })}
      </Box>
    </>
  );
};

export default FAQ;

const Section = ({ question, answer, index, addLink }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Box width="100%" id={index}>
      <Box width="90%" maxWidth="1000px" textAlign="left" m={"0 auto"}>
        <Button
          onClick={() => setOpen((t) => !t)}
          style={{
            width: "100%",
            margin: "0",
            padding: "0",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Box
            display="flex"
            width="95%"
            m={"auto 0"}
            justifyContent="space-between"
            textAlign="left"
            alignItems="center"
          >
            <BoxTypo
              fs={["12px", "14px"]}
              ls={["2px"]}
              lh={["200%"]}
              color={"#515D59"}
              my={["32px"]}
            >
              Q : {question}
            </BoxTypo>
            {isOpen ? (
              <ExpandLess style={{ margin: "12px 0", color: "#6D7C77" }} />
            ) : (
              <ExpandMore style={{ margin: "12px 0", color: "#6D7C77" }} />
            )}
          </Box>
        </Button>
        {isOpen ? (
          <Box m={"auto"} width="95%">
            <Box width="90%" maxWidth="800px" textAlign="justify">
              <BoxTypo
                fs={["12px", "14px"]}
                ls={["2px"]}
                lh={["200%"]}
                my={"32px"}
              >
                A : {nl2br(answer)}
              </BoxTypo>
              {addLink &&
                addLink.map((c, i) => {
                  return (
                    <>
                      <BoxTypo
                        fs={["12px", "14px"]}
                        ls={["2px"]}
                        lh={["200%"]}
                        my={["16px"]}
                      >
                        {c.title}
                      </BoxTypo>
                      <a href={c.link}>
                        <BoxTypo
                          fs={["12px", "14px"]}
                          ls={["2px"]}
                          lh={["200%"]}
                        >
                          {c.linkTitle ? c.linkTitle : c.link}
                        </BoxTypo>
                      </a>
                    </>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
